import { makeStyles } from '@material-ui/core/styles';

const actionPanelStyles = makeStyles({
	actionPanel: {
		width: '300px',
		display: 'grid',
		gridTemplateColumns: 'auto auto',
		gridRowGap: '20px',
		gridColumnGap: '20px',
	},
	root: {
		width:'145px',
		height:'145px'
	},
	formTextInput: {
		fontSize: '52px',
		textAlign: 'center'
	},
	formTextInputShortages: {
		fontSize: '52px',
		color: '#B04A14',
		textAlign: 'center'
	},
	label: {
		fontSize: '24px',
		transform: 'translate(20px, 20px) scale(1)',
	},
	outline: {
		fontSize: '24px',
	},
	actionBtn: {
		width:'145px',
		height:'145px',
		backgroundColor: '#FFFFFF',
		display: 'block',
		fontSize: '18px',
		fontWeight: 600,
		boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14)'
	},
	activeButton: {
		width:'145px',
		height:'145px',
		backgroundColor: '#FFD4BD',
		display: 'block',
		fontSize: '18px',
		fontWeight: 700,
	},
	iconImg: {
		width:'82px',
		height:'82px'
	},
	cancelButton: {
		width:'145px',
		height:'145px',
		backgroundColor: '#FFFFFF',
		display: 'block',
		fontSize: '18px',
		fontWeight: 600,
		color: '#B00020'
	}

})	

export default actionPanelStyles;