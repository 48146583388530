import React from 'react';
import {
  Link,
  withRouter
} from 'react-router-dom';
import icon_1 from '../../assets/icon_1.svg'
import icon_2 from '../../assets/icon_2.svg'
import icon_3 from '../../assets/icon_3.svg'
import { failure, machine, pageTitles } from '../../labels.json';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import headerStyles from './Header.styles.jsx';

const Header = ({ backButton, history, location, chosenMachine, task, user, logOut }) => {
  const classes = headerStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  let pageTitle = location.pathname.split('/')[1];

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const previousPage = () => {
    switch(pageTitle){
      case `rejectionReason`:
        history.push(`/taskDetails/${task.id}`)
        break
      case `taskSummary`:
        history.push(`/taskDetails/${task.id}`)
        break
      case `taskDetails`:
        history.push(`/tasksList/${machine.id}`)
        break
      case `tasksList`:
        history.push(`/machineSelection`)
        break
      default:
        history.push(`/machineSelection`)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {
            location.pathname==='/machineSelection'
            ? null
            : <KeyboardBackspaceIcon className={classes.backButton} onClick={previousPage}/>
          }         
          <Typography variant="h6" className={classes.headerTitle}>
            {pageTitles[pageTitle]}
          </Typography>
          <div className={classes.headerItem}>
	          <img src={icon_2} className={classes.headerIcon} alt="icon"/>
	          <Button color="inherit" className={classes.headerItemLabel} disabled>{failure}</Button>
          </div>
          <Link to="/machineSelection" className={classes.headerItem}>
	          <img src={icon_1} className={classes.headerIcon} alt="icon"/>
	          <Button color="inherit" className={classes.headerItemLabel}>{machine}</Button>
          </Link>
          <div 
	          className={classes.headerItem} 
	          ref={anchorRef}
	          aria-controls={open ? 'menu-list-grow' : undefined}
	          aria-haspopup="true"
	          onClick={handleToggle}
	      >
	          <img src={icon_3} className={classes.headerIcon} alt="icon"/>
	          <Button color="inherit" className={classes.headerItemLabel}>{user}</Button>
          </div>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper elevation={6} className={classes.logoutButton}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={logOut}>WYLOGUJ</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </Toolbar>
    </AppBar>
  );
}

export default withRouter(Header);