import { makeStyles } from '@material-ui/core/styles';

const headerStyles = makeStyles({
	appBar: {
		height: "82px",
	},
	backButton: {
		fontSize: '80px',
		color: 'rgba(255, 255, 255, 0.74)',
	},
	headerTitle: {
		fontSize: '28px',
		margin: 'auto',
		marginTop:'18px',
		color: 'rgba(255, 255, 255, 0.74)',	
	},
	headerItem: {
		display: 'block',
		margin: "14px 14px 0 0",
		textDecoration: 'none',
	},
	headerIcon: {
		display: 'block',
		margin: 'auto',
	},
	headerItemLabel: {
		fontSize: '16px',
		color: 'rgba(255, 255, 255, 0.74)',	
	},
	logoutButton: {
		background: '#E65100',
		color: 'rgba(255, 255, 255, 0.74)',
	}
})

export default headerStyles;