import { makeStyles } from '@material-ui/core/styles';

const taskDetailsStyles = makeStyles({
	container: {
		display: 'grid',
    	gridTemplateColumns: 'auto auto',
    	marginLeft: '6vw',
    	marginRight: '16vw',
	},
	taskContainer: {
		display: 'grid',
		gridRowGap: '3vh',
		margin: '6vh 5vw 2vh 5vw ',
		gridTemplateColumns: 'max-content'
	},
	formTextInput: {
		fontSize: '32px',
		padding: '20px',
	},
	label: {
		fontSize: '24px',
		transform: 'translate(20px, 20px) scale(1)',
	},
	outline: {
		fontSize: '24px',
	},
	panel: {
		margin: '6vh 2vw 0 0',
	}
})

export default taskDetailsStyles;