import { makeStyles } from '@material-ui/core/styles';

const statusIconStyles = makeStyles({
	tableCell:{
		fontSize: '38px',
		cursor: 'pointer',
	},
	inactive:{
		color: 'rgba(0, 0, 0, 0.12)',
		fontSize: '24px',
	}
})

export default statusIconStyles;