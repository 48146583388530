import { makeStyles } from '@material-ui/core/styles';

const signInStyles = makeStyles({
	logo: {
		display: 'block',
		width: '32vw',
		margin: 'auto',
		marginTop: '8vh',
	},
	tabs:{
		margin: '3vh 34vw 6vh 34vw ',
	},
	tab:{
		fontSize: '22px',
	},
	wrapper: {
	    display: 'grid',
	    gridTemplateRows: '1fr 1fr 1fr', 
	    gridRowGap: '30px',
	    width: '360px',
	    margin: '4vh auto',
	    fontSize: '20px'
	},
	formTextInput: {
		fontSize: '20px',
		padding: '20px'
	},
	label: {
		fontSize: '20px',
		transform: 'translate(20px, 20px) scale(1)',
	},
	outline: {
		fontSize: '22px',
	},
	button : {
		fontSize: '24px',
		padding: '10px'

	},
	helperText:{
		fontSize: '18px',
	}
})

export default signInStyles;