import React from 'react';

import { Alert, AlertTitle } from '@material-ui/lab';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import erroDialogStyles from './ErrorDialog.styles.js';

const ErrorDialog = (props) => {
  const classes = erroDialogStyles();
  const { onClose, errorMessage, open } = props;

  const handleClose = () => {
    onClose();
  };

  return ( 	
  	<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
  			<Alert severity="warning" variant='outlined' className={classes.alert}>
  			<AlertTitle className={classes.alertTitle}>Warning</AlertTitle>
  			{errorMessage}
  			</Alert>
    </Dialog>
  );
}

export default ErrorDialog;