import { makeStyles } from '@material-ui/core/styles';

const machinesListStyles = makeStyles({
	machinesList:{
		width: '604px',
		margin:'auto',
		marginTop: '10vh',
	},
	listTitle:{
		fontSize: '28px',
	}

})

export default machinesListStyles;