import React from 'react';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import RotateRightIcon from '@material-ui/icons/RotateRight';

import statusIconStyles from './StatusIcon.styles.js';

const StatusIcon = ({ index, status, variant }) => {
  const classes = statusIconStyles();
  const icon = {
  	'STARTED': <RotateRightIcon className={(index !== 0 && variant==='oneTaskActive') ? classes.inactive : classes.tableCell}/>,
    'READY_TO_WORK': <HourglassEmptyIcon className={(index !== 0 && variant==='oneTaskActive') ? classes.inactive : classes.tableCell}/>,
    'IN_QUEUE':<div>
        				<ArrowRightAltIcon className={classes.inactive}/>
        				<HourglassEmptyIcon className={classes.inactive}/>
        			</div>,
    'INTERRUPTED': <PauseCircleOutlineIcon className={(index !== 0 && variant==='oneTaskActive') ? classes.inactive : classes.tableCell}/>
  }
 
  return ( 	
  		<div>
        {icon[status]}
    </div>
  );
}

export default StatusIcon;