import { makeStyles } from '@material-ui/core/styles';

const taskSummaryStyles = makeStyles({
	container: {
		display: 'grid',
		gridRowGap: '2vh',
		margin: '6vh 5vw 2vh 5vw ',
		width: '700px'
	},
	formTextInput: {
		fontSize: '38px',
		padding: '20px',
	},
	label: {
		fontSize: '28px',
		transform: 'translate(20px, 20px) scale(1)',
	},
	outline: {
		fontSize: '30px',
	},
	timeField: {
		width:'300px'
	},
	title: {
		marginLeft: '18px'
	},
	actionBtn: {
		width:'145px',
		height:'145px',
		backgroundColor: '#FFFFFF',
		display: 'block',
		float: 'right',
		margin: '7vw 7vw',
		fontSize: '18px',
		fontWeight: 600,
		boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14)'
	},
	cancelButton: {
		width:'145px',
		height:'145px',
		backgroundColor: '#FFFFFF',
		display: 'block',
		float: 'right',
		margin: '7vw 7vw',
		fontSize: '18px',
		fontWeight: 600,
		color: '#B00020'
	},
	iconImg: {
		width:'82px',
		height:'82px'
	},
})

export default taskSummaryStyles;