import React, { useState, useEffect} from 'react';
import {
  withRouter
} from "react-router-dom";
import axios from 'axios';

import { authHeader } from '../../utils/auth-header';

import { host } from '../../../package.json';
import { selectRejectionReason } from '../../labels.json';

import RejectButton from '../../components/RejectButton/RejectButton.component'

import rejectionReasonStyles from './RejectionReason.styles.js';

const RejectionReason = ({location, username, logOut, setMachine}) => {
  const classes = rejectionReasonStyles();
  const [rejectionReasons, setRejectionReasons] = useState([]);

   useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `${host.url}work-rejection/reasons`,
        {headers: authHeader()}
      );
      setRejectionReasons(result.data);
    };
    fetchData();
  }, []);
  
  let task = location.state.task

  return (
    <div> 
    	<div className={classes.list}>
    	<p className={classes.listTitle}>{selectRejectionReason}:</p>
    	{
			rejectionReasons.map((reason) => {
		 		return (
		 			<RejectButton
		 				key={reason.id} 
		 				rejectionReason={reason} 
            task={task}
            actionType='reject'
		 			/>
		 		);
		 	})
		}
		</div>
    </div>
  );
}

export default withRouter(RejectionReason);