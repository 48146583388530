import React, { useState, useEffect } from 'react';
import { host } from '../../../package.json';
import { product, amount, material, emptyTaskList, order, status } from '../../labels.json';

import {
  withRouter 
} from 'react-router-dom';
import axios from 'axios';

import { authHeader } from '../../utils/auth-header';
import StatusIcon from '../../components/StatusIcon/StatusIcon.component';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import tasksListStyles from './TasksList.styles.js';

const TasksList = ({ history, machine, variant, setTask}) => {
	const classes = tasksListStyles();
	const [tasks, setTasks] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			    const result = await axios(
			      `${host.url}tasks/${machine.id}`,
			      {headers: authHeader()}
			    );
			    setTasks(result.data);;
		};
		fetchData()
		const interval = setInterval(() => {
			fetchData()  
		}, 10000)
		return () => clearInterval(interval);
	}, [machine.id]);

	const showTaskDetail = (row) => e => {
	  	history.push(`/taskDetails/${row.id}`, { task: row })
	  	setTask(row)
	  	localStorage.removeItem('duration')
	  }

	if (tasks.length === 0) {return <div className={classes.emptyListMessage}><h2>{emptyTaskList}</h2></div> }

  	return ( 	
		<div>
			<TableContainer component={Paper} className={classes.tableContainer}>
		      <Table className={classes.table}>
		        <TableHead>
		          <TableRow>
		            <TableCell className={classes.headerCell}>{product}</TableCell>
		            <TableCell className={classes.headerCell}>{amount}</TableCell>
		            <TableCell className={classes.headerCell}>{material}</TableCell>
		            <TableCell className={classes.headerCell}>{order}</TableCell>
		            <TableCell className={classes.headerCell} align="center">{status}</TableCell>
		          </TableRow>
		        </TableHead>
		        <TableBody>
		          {tasks.map((row, index) => (
		          	
		            <TableRow key={row.id} onClick={((index !== 0 && variant==='oneTaskActive') || row.status==='IN_QUEUE') ? null : showTaskDetail(row)}>
		              <TableCell component="th" scope="row" className={((index !== 0 && variant==='oneTaskActive') || row.status==='IN_QUEUE') ? classes.inactive : classes.tableCell}>
		                {row.detailName}
		              </TableCell>
		              <TableCell className={((index !== 0 && variant==='oneTaskActive') || row.status==='IN_QUEUE') ? classes.inactive : classes.tableCell}>{row.orderedAmount}</TableCell>
		              <TableCell className={((index !== 0 && variant==='oneTaskActive') || row.status==='IN_QUEUE') ? classes.inactive : classes.tableCell}>{row.product}
		              	<span className={((index !== 0 && variant==='oneTaskActive') || row.status==='IN_QUEUE') ? classes.inactive : classes.unit}> [{row.productAmount} {row.rawMaterialUnit}]</span>
		              </TableCell>
		              <TableCell className={((index !== 0 && variant==='oneTaskActive') || row.status==='IN_QUEUE') ? classes.inactive : classes.tableCell}>{row.orderNumber}</TableCell>
		              <TableCell align="center">
		              	<StatusIcon index={index} status={row.status} variant={variant}/>
		              </TableCell>
		            </TableRow>
		            
		          ))}
		        </TableBody>
		      </Table>
		    </TableContainer>
		</div>
	);
}

export default withRouter(TasksList);