import React from 'react';
import logo from '../../assets/fp_logo.png'
import { employee, logIn, manager } from '../../labels.json';
import { host } from '../../../package.json';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import signInStyles from './Sign-in.styles.js';

const SignIn = ({logInUser, loginError}) => {
  const classes = signInStyles();
  const [factoryAccount] = React.useState('strama');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setshowPassword] = React.useState(false);
  const [role, setRole] = React.useState('employee');

  const handleChange = (event, newValue) => {
    setRole(newValue);
    console.log(newValue)
  };

  const handleUsernameChange = event => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleLogin = () => {
    logInUser(role, factoryAccount, username, password)
  }

  const loginOnEnter = (e) => {
    if(e.keyCode === 13){
      handleLogin();
    }
  }

  return (
    <div>
      <img src={logo} className={classes.logo} alt="logo"/>
      <Paper className={classes.tabs}>
        <Tabs
          value={role}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          onChange={handleChange}
        >
          <Tab label={manager} value='manager' className={classes.tab} href={host.factory_plus_url}/>
          <Tab label={employee} value='employee' className={classes.tab}/>
        </Tabs>
      </Paper>
      <div className={classes.wrapper}>
        <TextField
          label="FactoryID"
          value={factoryAccount}
          variant="outlined"
          fullWidth
          InputProps={{
            readOnly: true,
            classes: {
              input: classes.formTextInput,
              notchedOutline: classes.outline,
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
            }
          }}
        />
        <TextField
          error={loginError ? true : false }    
          helperText={loginError ? "Nieprawidłowe dane logowania" : "" }
          label="Login"
          variant="outlined"
          fullWidth
          onChange={handleUsernameChange}
          InputProps={{
            classes: {
              input: classes.formTextInput,
              notchedOutline: classes.outline,
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
            }
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.helperText,
            }
          }}
        />
        <FormControl 
          variant="outlined" 
          className={classes.input} 
          error={loginError ? true : false}    
        >
          <InputLabel 
            htmlFor="outlined-adornment-password" className={classes.label}>Hasło</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{
              className:classes.formTextInput
            }}
            labelWidth={100}
            onKeyDown={loginOnEnter}
          />
          <FormHelperText className={classes.helperText}>{loginError ? "Nieprawidłowe dane logowania" : "" }</FormHelperText>
        </FormControl>
        <Button 
          variant="contained" 
          color="primary" 
          size="large" 
          disableElevation 
          className={classes.button}
          fullWidth
          onClick={() => handleLogin()}
        >
          {logIn}
        </Button>
      </div>
    </div>
  );
}

export default SignIn;