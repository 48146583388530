import React from 'react';
import { host, variant } from '../package.json';
import axios from 'axios';
import { authHeader } from './utils/auth-header';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  withRouter 
} from 'react-router-dom';

import Header from './components/Header/Header.component'
import MachinesList from './pages/MachinesList/MachinesList.component'
import RejectionReason from './pages/RejectionReason/RejectionReason.component'
import TasksList from './pages/TasksList/TasksList.component'
import TaskDetails from './pages/TaskDetails/TaskDetails.component'
import TaskSummary from './pages/TaskSummary/TaskSummary.component'
import SignIn from './pages/Sign-in/Sign-in.component'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
   palette: {
      primary: {
         light: '#fff',
         main: '#E65100',
         dark: '#ddd'
      },
      secondary: {
        main: '#ccc',
      },
   },
   typography: { 
      useNextVariants: true
   }
});

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...rest}/>
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);

const App = (props) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(
    localStorage.getItem('user') || ''
  );
  const [username, setUsername] = React.useState(
    localStorage.getItem('user') || ''
  );
  const [machine, setMachine] = React.useState(
    JSON.parse(localStorage.getItem('machine'))|| {}
  );
  const [task, setTask] = React.useState(
    JSON.parse(localStorage.getItem('task')) || {}
    );

  const [loginError, setLoginError] = React.useState(false);

  const logInUser = async (role, factoryAccount, username, password) => {
    try {
      let res = ''
      if (role === 'employee') {
        res = await axios.post(
        `${host.url}login?username=${username}&password=${password}&factoryAccount=${factoryAccount}`
        )
      }     

      let { data } = res
      if (data.success===true) {
        let authData = window.btoa(username + ':' + password);
        localStorage.setItem('user', username)
        localStorage.setItem('authData', JSON.stringify(authData))
        localStorage.setItem('factoryAccountId', data.factoryAccountId)
        setIsLoggedIn(true)
        setUsername(username)
        setLoginError(false)
        } else {
        setLoginError(true)
      }
    } catch (e) {
      setLoginError(true)
    }
    
  };

  const logOut = async () => {
    await axios.post(
      `${host.url}logout?username=${username}`,{}, {headers: authHeader()});
    setIsLoggedIn(false)
    localStorage.setItem('user', '')
    props.history.push('/')
  };

  const changeMachine = machine => {
    setMachine(machine)
    const chosenMachine = JSON.stringify(machine);
    localStorage.setItem('machine', chosenMachine)
  }

  const changeTask = task => {
    setTask(task)
    const chosenTask = JSON.stringify(task);
    localStorage.setItem('task', chosenTask)
  }

  const variants = {
    1: 'allTasksActive',
    2: 'oneTaskActive'
  }

  return (
    <Router basename='/app'>
      <div className="App">
        <MuiThemeProvider theme = { theme }>
          {isLoggedIn ? <Header user={username} logOut={logOut} task={task} chosenMachine={machine}/> : null}
          <PrivateRoute 
            path="/machineSelection" 
            isLoggedIn={isLoggedIn} 
            component={() => <MachinesList username={username} setMachine={changeMachine}/>}
          />
          <PrivateRoute 
            path="/tasksList/:machineId" 
            isLoggedIn={isLoggedIn} 
            component={() => <TasksList machine={machine} setTask={changeTask} variant={variants[variant]}/>}
          />
          <PrivateRoute 
            path="/taskDetails/:taskId" 
            isLoggedIn={isLoggedIn} 
            component={() => <TaskDetails task={task} username={username}/>}
          />
          <PrivateRoute 
            path="/taskSummary/:taskId" 
            isLoggedIn={isLoggedIn} 
            component={() => <TaskSummary username={username}  machine={machine} task={task}/>}
          />
          <PrivateRoute
            path="/rejectionReason/:taskId"
            isLoggedIn={isLoggedIn}
            component={() => <RejectionReason logOut={logOut} username={username}  machine={machine}/>}
          />                                                 
          <Route 
            exact 
            path='/' 
            render={() => 
              isLoggedIn ? (
                <Redirect to='/machineSelection'/>
              ) : (
                <SignIn logInUser={logInUser} loginError={loginError}/>
              )
            }
          />
        </MuiThemeProvider> 
      </div>
    </Router>
  )
}

export default withRouter(App);
