import React from 'react';

import { host, file_storage_path } from '../../../package.json';
import attachmentImg from '../../assets/attachment.png'
import { attachments } from '../../labels.json';

import Link from '@material-ui/core/Link';
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import attachmentsStyles from './Attachments.styles.js';

const Attachments = ({task}) => {
  const classes = attachmentsStyles();

  let attachmentsList = task.attachments.map((attachment) => (
     <Link href={`${host.attachmentUrl}${file_storage_path}${attachment}`} target="_blank" key={attachment}>
       <img src={attachmentImg} className={classes.attachmentImg} alt="attachmentImg"/>
       {attachment.endsWith("pdf") ? <PictureAsPdfIcon className={classes.icon}/> : 
       attachment.endsWith("jpg") ? <ImageIcon className={classes.icon}/> : 
       <DescriptionIcon className={classes.icon}/>}
     </Link>)
  )
  
  return ( 	
    <div>
        <div className={classes.attachementsContainer}>
          <p className={classes.attachements}>{attachments}:</p>
           { task.attachments ? attachmentsList : null }
        </div>
    </div> 
  );
}

export default Attachments;