import { makeStyles } from '@material-ui/core/styles';

const rejectionReasonStyles = makeStyles({
	list:{
		width: '800px',
		margin:'auto',
		marginTop: '4vh',
	},
	listTitle:{
		fontSize: '28px',
	}

})

export default rejectionReasonStyles;