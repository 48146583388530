import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { host } from '../../../package.json';
import { selectMachine } from '../../labels.json';

import { authHeader } from '../../utils/auth-header';
import MachineButton from '../../components/MachineButton/MachineButton.component'

import machinesListStyles from './MachinesList.styles.js';

const MachinesList = ({username, setMachine}) => {

  const classes = machinesListStyles();
  const [machines, setMachines] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `${host.url}user/assigned-machines/${username}`, {headers: authHeader()}
      );
      setMachines(result.data);
    };
    fetchData();
  }, [username]);
 
  return (
    <div>  
    	<div className={classes.machinesList}>
    	<p className={classes.listTitle}>{selectMachine}:</p>
    	{
			machines.map((machine) => {
		 		return (
			 			<MachineButton
			 				key={machine.id} 
			 				machine={machine} 
              setMachine={setMachine}
			 			/>
		 		);
		 	})
		}
		</div>
    </div>
  );
}

export default MachinesList;