import { makeStyles } from '@material-ui/core/styles';

const tasksListStyles = makeStyles({
	tableContainer: {
		width: '95vw',
		margin: 'auto',
		marginTop: '10vh',
	},
	headerCell: {
		color: 'rgba(0, 0, 0, 0.6)',
		fontSize: '28px',
	},
	tableCell:{
		fontSize: '28px',
		cursor: 'pointer',
	},
	inactive:{
		color: 'rgba(0, 0, 0, 0.12)',
		fontSize: '24px',
	},
	emptyListMessage:{
		marginTop: '8vh',
		textAlign: 'center',
	},
	unit: {
		color: '#B04A14'
	}
})

export default tasksListStyles;