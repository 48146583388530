import React from 'react';
import { host } from '../../../package.json';
import {
  withRouter 
} from 'react-router-dom';
import axios from 'axios';

import { actualTime, completed, estimatedTime, finish, materialUse, product, reject, shortages } from '../../labels.json';
import { authHeader } from '../../utils/auth-header';
import ErrorDialog from '../../components/ErrorDialog/ErrorDialog.component'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import taskSummaryStyles from './TaskSummary.styles.js';

const TaskSummary = ({ actionType, history, location, machine, task, username}) => {
	const classes = taskSummaryStyles();

	let action = ''
	actionType ? action = actionType : action = location.state.action

	const [materialUsage, setMaterialUsage] = React.useState(0);
	const [completedCount, setCompletedCount] = React.useState(location.state.completedCount);
	const [shortageCount, setShortageCount] = React.useState(location.state.shortagesCount);
	const [open, setOpen] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');

  	const openDialog = () => {
    	setOpen(true);
  	};

	const handleClose = value => {
	    setOpen(false);
	};

	const materialChange = event => {
		setMaterialUsage(event.target.value);
	}

	const completedCountChange = event => {
		setCompletedCount(event.target.value);
	};

	const shortageCountChange = event => {
		setShortageCount(event.target.value);
	};

	const handleReportUsage = async () => {
		if (materialUsage > 0) {
			let usage = {
	 			"foreman": username,
	   			"orderDetailMachineId": task.id,
	   			"productId": task.productId,
	  			"usedAmount": materialUsage
	 		}
			try {
		    	await axios.put(`${host.url}products/usage`, usage, {headers: authHeader()})  
		    	handleFinishTask()		
	    	} catch (e) {
	    		setErrorMessage(e.response.data[0].message);
	    		openDialog() 
	    	} 
    	} else {
    		handleFinishTask()
    	}
   	};

   	const handleFinishTask = async () => {
   		let finishedTask = {
			"doneAmount": completedCount,
			"foremanLogin": username,
			"shortageAmount": shortageCount,
			"taskId": task.id
		}
    	await axios.put(`${host.url}tasks/finish`, finishedTask, {headers: authHeader()})
    	history.push(`/tasksList/${machine.id}`)
   	};

  	return ( 	
		<div>
			<div className={classes.container}>
		        <TextField
		          label={product}
		          value={task.detailName}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		              notchedOutline: classes.outline,
		            }
		          }}
		          InputLabelProps={{
		            classes: {
		              root: classes.label,
		            }
		          }}
		        />
		        <Grid container direction="row" justify="space-between">
		        <Grid item xs={5}>
		        <TextField
		          label={estimatedTime}
		          value={task.estimatedDuration}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		              notchedOutline: classes.outline,
		            }
		          }}
		          InputLabelProps={{
		            classes: {
		              root: classes.label,
		            }
		          }}
		        />
		        </Grid>
		        <Grid item xs={5}>
		        <TextField
		          label={actualTime}
		          value={location.state.duration || 0}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		              notchedOutline: classes.outline,
		            }
		          }}
		          InputLabelProps={{
		            classes: {
		              root: classes.label,
		            }
		          }}
		        />
		        </Grid>
		        </Grid>
		        <br/>
		        <Typography variant="h5" color="textSecondary" className={classes.title}>
		          {materialUse}
		        </Typography>
		        <Grid container direction="row" justify="space-between" spacing={3}>
		        <Grid item xs={7}>
		        <TextField
		          value={task.product}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		            }
		          }}
		        />
		        </Grid>
		        <Grid item xs={3}>
		        <TextField
		          value={materialUsage}
		          type="number"
		          variant="outlined"
		          onChange={materialChange}
		          InputProps={{
		            classes: {
		              input: classes.formTextInput
		            }
		          }}
		        />
		        </Grid>
		        <Grid item xs={2}>
		       	<TextField
		          value={task.rawMaterialUnit}
		          variant="outlined"
		          InputProps={{
		            classes: {
		              input: classes.formTextInput,
		            }
		          }}
		        />
		        </Grid>
		        </Grid>
		        <Typography variant="h5" color="textSecondary" className={classes.title}>
		          {completed}
		        </Typography>
		        <Grid container direction="row" spacing={3}>
		        <Grid item xs={3}>
		        <TextField
		          value={completedCount}
		          type="number"
		          variant="outlined"
		          onChange={completedCountChange}
		          InputProps={{
		            classes: {
		              input: classes.formTextInput,
		            }
		          }}
		        />
		        </Grid>
		        <Grid item xs={2}>
		        <TextField
		          value={task.unit}
		          variant="outlined"
		          InputProps={{
		            classes: {
		              input: classes.formTextInput,
		            }
		          }}
		        />
		        </Grid>
		        </Grid>
		        <Typography variant="h5" color="textSecondary" className={classes.title}>
		          {shortages}
		        </Typography>
		        <Grid container direction="row" spacing={3}>
		        <Grid item xs={3}>
		        <TextField
		          value={shortageCount}
		          type="number"
		          onChange={shortageCountChange}
		          variant="outlined"
		          InputProps={{
		            classes: {
		              input: classes.formTextInput,
		            }
		          }}
		        />
		        </Grid>
		        <Grid item xs={2}>
		        <TextField
		          value={task.unit}
		          variant="outlined"
		          InputProps={{
		            classes: {
		              input: classes.formTextInput,
		            }
		          }}
		        />
		        </Grid>
		        </Grid>
		    </div>
		    {action==='finish'
			? <Button variant="contained" className={classes.actionBtn} onClick={handleReportUsage}>
                <CheckCircleOutlineIcon className={classes.iconImg}/>
                {finish}
              </Button>
			: <Button variant="contained" className={classes.cancelButton} >
                  <HighlightOffIcon className={classes.iconImg}/>
                  {reject}
              </Button>
            }
            <ErrorDialog errorMessage={errorMessage} open={open} onClose={handleClose} />
		</div>
	);
}

export default withRouter(TaskSummary);