import React from 'react';
import axios from 'axios';
import {
  withRouter
} from 'react-router-dom';

import { authHeader } from '../../utils/auth-header';

import { host } from '../../../package.json';
import { completed, finish, reject, shortage, shortages, start, stop} from '../../labels.json';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

import actionPanelStyles from './ActionPanel.styles.js';

const ActionPanel = ({history, task, username}) => {
  const classes = actionPanelStyles();
  const [completedCount, setCompletedCount] = React.useState(task.actualAmount);
  const [shortagesCount, setShortagesCount] = React.useState(0);
  const [status, changeStatus] = React.useState(task.status)

  const increaseCompleted = async () => {
    await axios.put(
      `${host.url}tasks/update-amount/${task.id}/${completedCount+10}`, {},
      {headers: authHeader()}
    );
    setCompletedCount(completedCount+10)
  };

  const increaseShortages = () => {
    setShortagesCount(shortagesCount+1)
  };

  const startTask = async () => {
    await axios.put(
      `${host.url}tasks/start/${username}/${task.id}`, {},
      {headers: authHeader()}
    );
    changeStatus('STARTED');
    const date = new Date()
    localStorage.setItem('startTime', JSON.stringify(date.getTime()))
  };

  const stopTask = async () => {
    await axios.put(
      `${host.url}tasks/stop/${username}/${task.id}`, {},
      {headers: authHeader()}
    );
    changeStatus('INTERRUPTED');
  };

  let duration = 0

  const finishTask = () => {
    calculateDuration()
    history.push(`/taskSummary/${task.id}`, { task: task, action: 'finish', completedCount:completedCount, shortagesCount:shortagesCount, duration:duration })
  };

  const calculateDuration = () => {
    const date = JSON.parse(localStorage.getItem('startTime'))  
    if (date) {
      const endDate = new Date()
      duration = Math.round((endDate.getTime() - date)/60000)
      localStorage.setItem('duration', JSON.stringify(duration))
      localStorage.removeItem('startTime')
    } else {
      duration = JSON.parse(localStorage.getItem('duration'))
    }
  }

  const rejectTask = () => {
    history.push(`/rejectionReason/${task.id}`, { task: task })
  }

  return ( 	
    <div className={classes.actionPanel}>
      <TextField
        label={completed}
        value={completedCount}
        variant="outlined"
        InputProps={{
          readOnly: true,
          classes: {
            root: classes.root,
            input: classes.formTextInput,
            notchedOutline: classes.outline,
            }
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
          }
        }}
      />
      <TextField
        label={shortages}
        value={shortagesCount}
        variant="outlined"
        InputProps={{
          readOnly: true,
          classes: {
            root: classes.root,
            input: classes.formTextInputShortages,
            notchedOutline: classes.outline,
          }
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
          }
        }}
      />
      <Button variant="contained" className={classes.actionBtn} onClick={increaseCompleted}>
                  <ControlPointIcon className={classes.iconImg}/>
                  +10
                </Button>
      <Button variant="contained" className={classes.actionBtn} onClick={increaseShortages}>
                  <RemoveCircleOutlineIcon className={classes.iconImg}/>
                  {shortage}
                </Button>
      <Button variant="contained" 
              className={status === 'STARTED' ? classes.activeButton :classes.actionBtn} 
              onClick={startTask}
              disabled={status === 'IN_QUEUE' ? true : false}
      >
                  <PlayCircleOutlineIcon className={classes.iconImg}/>
                  {start}
                </Button>
      <Button variant="contained" className={status === 'INTERRUPTED' ? classes.activeButton :classes.actionBtn} onClick={stopTask}>
                  <PauseCircleOutlineIcon className={classes.iconImg}/>
                  {stop}
                </Button>
      <Button variant="contained" className={classes.actionBtn} onClick={finishTask}>
                  <CheckCircleOutlineIcon className={classes.iconImg}/>
                  {finish}
                 </Button>
      <Button variant="contained" className={classes.cancelButton} onClick={rejectTask} disabled>
                  <HighlightOffIcon className={classes.iconImg}/>
                  {reject}
                 </Button>
    </div> 
  );
}

export default withRouter(ActionPanel);