export function authHeader() {
    // return authorization header with basic auth credentials
    let authData = JSON.parse(localStorage.getItem('authData'));
    let factoryAccountId = JSON.parse(localStorage.getItem('factoryAccountId'));

    if (authData) {
        return { 'Authorization': 'Basic ' + authData, 'FACTORY_ACCOUNT_ID': factoryAccountId };
    } else {
        return {};
    }
}