import { makeStyles } from '@material-ui/core/styles';

const machineButtonStyles = makeStyles({
	root: {
		width: '800px',
		height: '94px',
		display: 'flex',
		margin: '30px',	
	},
	rippleButton: {
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		verticalAlign: 'middle',
	},
	icon: {
		height: '94px',
		width: '94px',
		background: 'rgba(0, 0, 0, 0.12)',
		marginRight: '28px',
	},
	buttonText:{
		fontSize: '38px',
		alignSelf: 'center',
		float: 'left',
    	marginRight: 'auto',
	},
	iconImg: {
		height: '36px',
		width: '35px',
		margin: '30% 30%',
		color: 'rgba(0, 0, 0, 0.6)',
	},
	actionArrow: {
		height: '58px',
		width: '58px',
		marginRight: '20px',
	}
})

export default machineButtonStyles;