import { makeStyles } from '@material-ui/core/styles';

const attachmentsStyles = makeStyles({
	attachementsContainer:{
		marginTop: 0
	},
	attachements:{
		fontSize: '24px',
		color:'rgba(0, 0, 0, 0.6)',
		margin: 0,
	},
	attachmentImg: {
		margin: '1vw',
		cursor: 'pointer',
	},
	icon: {
		fontSize: 50,
		marginLeft: -50
	}
})	

export default attachmentsStyles;