import React from 'react';
import {
  withRouter 
} from 'react-router-dom';

import BuildIcon from '@material-ui/icons/Build';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import machineButtonStyles from './MachineButton.styles.js';

const MachineButton = ({ machine, history, setMachine, variant}) => {
  const classes = machineButtonStyles();

  const showTasksList = () => {
  	history.push(`/tasksList/${machine.id}`);
  	setMachine(machine)
  }
 
  return ( 	
    <Card className={classes.root} elevation={4}>
	    <ButtonBase className={classes.rippleButton} onClick={showTasksList}>
	      	<div className={classes.machineIcon}>
	    		<BuildIcon className={classes.iconImg}/>
	    	</div>
	    	<div className={classes.machineName}>{machine.name} {machine.machineNumber}</div>
	    	<div>
	    		<ChevronRightIcon className={classes.actionArrow}/>
	    	</div>
	    </ButtonBase>
    </Card>    
  );
}

export default withRouter(MachineButton);