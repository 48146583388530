import { makeStyles } from '@material-ui/core/styles';

const erroDialogStyles = makeStyles({
	alertTitle: {
	    fontSize: '32px'
	},
	alert: {
		fontSize: '28px',
		"& .MuiAlert-icon": {
	      fontSize: 40,
	      paddingTop: '10px'
	    }
	}
})

export default erroDialogStyles;