import React from 'react';
import {
  withRouter 
} from 'react-router-dom';

import BuildIcon from '@material-ui/icons/Build';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import rejectButtonStyles from './RejectButton.styles.js';

const RejectButton = ({ history, rejectionReason, setMachine, task, variant }) => {
  const classes = rejectButtonStyles();

  const rejectTask = () => {
  	history.push(`/taskSummary/${task.id}`, { task: task, action: 'reject' })
  }
 
  return ( 	
    <Card className={classes.root} elevation={4}>
	    <ButtonBase className={classes.rippleButton} onClick={rejectTask}>
	      	<div className={classes.icon}>
	    		<BuildIcon className={classes.iconImg}/>
	    	</div>
	    	<div className={classes.buttonText}>{rejectionReason.label}</div>
	    	<div>
	    		<ChevronRightIcon className={classes.actionArrow}/>
	    	</div>
	    </ButtonBase>
    </Card>    
  );
}

export default withRouter(RejectButton);