import React from 'react';

import { acceptanceDate, amount, client, material, number, product } from '../../labels.json';

import {
  withRouter 
} from 'react-router-dom';

import ActionPanel from '../../components/ActionPanel/ActionPanel.component';
import Attachments from '../../components/Attachments/Attachments.component';


import TextField from '@material-ui/core/TextField';

import taskDetailsStyles from './TaskDetails.styles.js';

const TaskDetails = ({ location, task, username}) => {
	const classes = taskDetailsStyles();

  	return ( 	
		<div>
		<div className={classes.container}>
			<div className={classes.taskContainer}>
		        <TextField
		          label={product}
		          value={task.detailName}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		              notchedOutline: classes.outline,
		            }
		          }}
		          InputLabelProps={{
		            classes: {
		              root: classes.label,
		            }
		          }}
		        />
		        <TextField
		          label={material}
		          value={`${task.product} [${task.productAmount} ${task.rawMaterialUnit}]`}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		              notchedOutline: classes.outline,
		            }
		          }}
		          InputLabelProps={{
		            classes: {
		              root: classes.label,
		            }
		          }}
		        />
		        <TextField
		          label={amount}
		          value={task.orderedAmount}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		              notchedOutline: classes.outline,
		            }
		          }}
		          InputLabelProps={{
		            classes: {
		              root: classes.label,
		            }
		          }}
		        />
		        <TextField
		          label={number}
		          value={task.orderNumber}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		              notchedOutline: classes.outline,
		            }
		          }}
		          InputLabelProps={{
		            classes: {
		              root: classes.label,
		            }
		          }}
		        />
		        <TextField
		          label={client}
		          value={task.clientName}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		              notchedOutline: classes.outline,
		            }
		          }}
		          InputLabelProps={{
		            classes: {
		              root: classes.label,
		            }
		          }}
		        />
		        <TextField
		          label={acceptanceDate}
		          value={task.orderAddDate}
		          variant="outlined"
		          InputProps={{
		            readOnly: true,
		            classes: {
		              input: classes.formTextInput,
		              notchedOutline: classes.outline,
		            }
		          }}
		          InputLabelProps={{
		            classes: {
		              root: classes.label,
		            }
		          }}
		        />
		        {task.attachments.length>0 ? <Attachments task={task}/> : null }
		    </div>
	        <div className={classes.panel}>
	        	<ActionPanel task={task} username={username}/>
	        </div>
	        
	    </div>
		</div>
	);
}

export default withRouter(TaskDetails);